/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import '../form/form.css'

const Form = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const eventUrl = 'https://superstudent.niecindonesia.com/event/';
    const eventToken = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'

    const onSubmit = data => {
        data = {
            ...data,
            berkas: data.berkas[0],
            file_name_berkas: data.berkas[0].name,
        }
        axios.post(eventUrl + 'arnantyoscholarship', data, {
            headers: {
                'Authorization': `Bearer ` + eventToken,
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                setLoading(false);
                Swal.fire({
                    title: 'Pendaftaran Beasiswa Berhasil!',
                    text: 'Tunggu Hasil Pengumuman Interview Pada Tanggal 19 Mei 2025',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    window.location.reload(false)
                })
            })
            .catch(err => console.log(err))
    }

    const handleDownload = () => {
        const fileUrl = "surat-pernyataan.pdf"; // Path relatif dari public/
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "surat-pernyataan.pdf"; // Nama file saat diunduh
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='form'>
            <h4>Form Pendaftaran</h4>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-input">
                    <input {...register("nama", { required: 'Nama Wajib Diisi' })} type="text" className={`form-control ${errors.nama?.message && 'is-invalid'}`} id="name" placeholder="Nama" />
                    {errors.nama?.message && (<div className="invalid-feedback">{errors.nama?.message}</div>)}
                </div>
                <div className="form-input">
                    <input {...register("email", { required: 'Email Wajib diisi', pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, message: 'Email tidak valid' } })} type="text" className={`form-control ${errors.email?.message && 'is-invalid'}`} id="email" placeholder="Email Address" />
                    {errors.email?.message && (<div className="invalid-feedback">{errors.email?.message}</div>)}
                </div>
                <div className="form-input">
                    <input {...register("notelp", { required: 'Nomor Telepon Wajib Diisi' })} type="number" className={`form-control ${errors.notelp?.message && 'is-invalid'}`} id="notelp" placeholder="No Handphone" />
                    {errors.notelp?.message && (<div className="invalid-feedback">{errors.notelp?.message}</div>)}
                </div>
                <div className="form-input">
                    <input {...register("sekolah", { required: 'Nama Sekolah Wajib Diisi' })} type="text" className={`form-control ${errors.sekolah?.message && 'is-invalid'}`} id="sekolah" placeholder="Sekolah" />
                    {errors.sekolah?.message && (<div className="invalid-feedback">{errors.sekolah?.message}</div>)}
                </div>
                <div className="form-input">
                    <input {...register("username_ig")} type="text" className={`form-control ${errors.username_ig?.message && 'is-invalid'}`} id="username_ig" placeholder="Username Instagram" />
                    {errors.username_ig?.message && (<div className="invalid-feedback">{errors.username_ig?.message}</div>)}
                </div>
               <button className='btn' type='button' style={{backgroundColor:'#E9ECEF', width:'100%'}}  onClick={handleDownload}>Download surat pernyataan</button>
                <div style={{ marginBottom: '10px' }}></div>
                <div className="form-input">
                    <input {...register('berkas',
                        {
                            required: 'Berkas Wajib Diisi', validate: {
                                size: (files) => files[0]?.size < 5000000 || "Ukuran berkas maksimal 4mb",
                                acceptedFormats: (files) => ["application/x-zip-compressed", "application/pdf"].includes(files[0]?.type) || "Berkas harus berupa .zip atau .pdf"
                            }
                        })}
                        accept=".zip,.rar,.7zip,.pdf"
                        className={`form-control ${errors.berkas?.message && 'is-invalid'}`} type="file" id="formFile" />
                    {errors.berkas?.message && (<div className="invalid-feedback">{errors.berkas?.message}</div>)}
                </div>
                <p>*Upload berkas sesuai syarat dibawah (Pastikan semua berkasmu telah menjadi satu file *pdf atau *zip), max File 4MB</p>
                <div className="form-input">
                    {
                        !loading ? (<button className='btn'>Daftar</button>) : (<button className='btn disabled'>Daftar...</button>)
                    }
                </div>
            </form>
        </div>
    )
}

export default Form