/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react'
import '../style.css'
import CardTahap from '../components/card-tahap/CardTahap'

const Tahapan = () => {
    const tahap = [
        { title: 'Tahap I Event Info Session Beasiswa', tgl: '10 Mei 2025' },
        { title: 'Tahap II Pengumpulan Berkas', tgl: '3 Maret - 12 Mei 2025' },
        { title: 'Tahap III Interview', tgl: '15 - 16 Mei 2025' },
        { title: 'Tahap IV Pengumuman Pemenang', tgl: '19 Mei 2025' },
    ]

    const [Days, setDays] = useState('');
    const [Hours, setHours] = useState('');
    const [Minutes, setMinutes] = useState('');
    const [Seconds, setSeconds] = useState('');

    let interval = useRef();

    const startTimer = () => {
        const countdownDate = new Date('May 10, 2025 00:00:00').getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hour = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current);
            } else {
                setDays(days);
                setHours(hour);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }, 1000);
    }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        }
    });

    return (
        <section id='tahapan'>
            <div className="container">
                <div className="daftar">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cara-daftar">
                                <h2>Cara Daftar?</h2>
                                <p>Untuk pendaftaran, kamu bisa isi form registrasi yang terletak di atas halaman ini, kemudian upload dan kirim berkasmu sebelum tanggal 12 Mei 2025. Kamu akan menerima follow up via Whatsapp dari kami disetiap kelolosan tahap. Untuk informasi lebih lanjut kamu bisa hubungi ke nomor <a href="tel:+6281239483761">081 239 483 761</a> atau follow Instagram kami <a href="https://www.instagram.com/ihms.bali/">@ihms.bali</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <section id='persyaratan'>
                                <div className="box">
                                    <div className="header">
                                        <h4>Persyaratan Berkas</h4>
                                    </div>
                                    <div className="body">
                                        <ol>
                                            <li>Ijazah SMA/SMK/Pendidikan Terakhir</li>
                                            <li>Nilai Rapor SMA/SMK Kelas 12 atau Transkrip Nilai Kuliah</li>
                                            <li>CV in English</li>
                                            <li>Motivation Letter in English</li>
                                            <li>Screenshot bukti follow IG @ihms.bali & @arnantyo</li>
                                            <li>Surat pernyataan yang sudah di tandatangani</li>
                                        </ol>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='row text-center mt-5'>
                    <div className='col-lg-12'>
                        <h4>Countdown Registration</h4>
                    </div>
                </div>
                <div className='row justify-content-center text-center mt-5 mb-3'>
                    <div className='col-lg-2 col-sm-2'>
                        <h4>{Days} Days</h4>
                    </div>
                    <div className='col-lg-2 col-sm-2'>
                        <h4>{Hours} Hours</h4>
                    </div>
                    <div className='col-lg-2 col-sm-2'>
                        <h4>{Minutes} Minutes</h4>
                    </div>
                    <div className='col-lg-2 col-sm-2'>
                        <h4>{Seconds} Seconds</h4>
                    </div>
                </div>
                <div className='mid-btn'>
                    <a href="#daftar"><button className='btn'>Daftar Sekarang</button></a>
                </div>
                <div className="tahap">
                    <div className="row justify-content-center">
                        {tahap.map((t, i) => {
                            return (
                                <div key={i} className="col-lg-3">
                                    <CardTahap title={t.title} body={t.tgl} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tahapan